import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import * as packageJson from '../package.json';
import ThemeTokens from '../../../src/ThemeTokens';
import { DefaultTheme } from '../src/themes';
import { UiLineSeparator } from '@uireact/separator';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "default-theme"
    }}>{`Default theme`}</h1>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <br />
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/foundation/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation`}</p>
    </blockquote>
    <p>{`@uireact provides with a `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/foundation/src/themes/default-theme.ts"
      }}>{`default theme`}</a>{` in the foundation package,
the specifics of the default theme are:`}</p>
    <h3 {...{
      "id": "light-coloration-"
    }}>{`Light Coloration 🤍`}</h3>
    <p>{`You can see the light coloration of the provided theme here: `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/foundation/src/themes/default-colors/light.ts"
      }}>{`Light Coloration`}</a></p>
    <ThemeTokens colors={DefaultTheme.light} mdxType="ThemeTokens" />
    <h3 {...{
      "id": "dark-coloration-"
    }}>{`Dark Coloration 🖤`}</h3>
    <p>{`You can see the dark coloration of the provided theme here: `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/foundation/src/themes/default-colors/dark.ts"
      }}>{`Dark Theme`}</a></p>
    <ThemeTokens colors={DefaultTheme.dark} mdxType="ThemeTokens" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      